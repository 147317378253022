.notification-page {
  background: rgb(228, 228, 228);
  width: 100%;
  min-height: 105vh;
}


.notification-page-header {
  margin: 0 auto;
  width: fit-content;
  text-align: center;
  max-width: 800px;
}

#select-notification-type {
  margin-left: 5px;
}

.notifications-list-container {
  width: fit-content;
  max-width: 800px;
  margin: 0 auto;
}

.notification-show {
  display: grid;
}

.notification-hide {
  display: none !important;
}


.notifications-container, .notifications-container:hover {
  position: absolute;
  top: 49px;
  right: 0px;
  max-height: 400px;
  background: white;
  color: black !important;
  border: 1px solid black;
  border-radius: 5px;
  overflow: auto;
  cursor: auto;
  width: 300px;
  box-shadow: 0 0 6px #777;
}

.notification-single-container {  
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 5px;
  background: rgb(228,228,228);
  margin-bottom: 3px;
  overflow: hidden;  
  color: black;
  text-decoration: none;
}

.notification-single-container:hover {
  color: black;
}

.notification-single-container>* {
  display: inline-block;
}

.notification-user-avatar {
  grid-row: 1/span 2;
  grid-column: 1;
  max-height: 50px;
  margin: auto;
  border-radius: 50%;
}

.notification-message {
  grid-row: 1;
  grid-column: 2;
  max-width: 200px;
}

.notification-timestamp {
  grid-row: 2;
  grid-column: 2;
  max-width: 200px;
}


.notifications-scroll-message {
  margin-bottom: 10px;
}

#notifications-bottom {
  text-align: center;
  min-height: 10px;
}

.notification-page-single-container {
  text-align: left;
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-column-gap: 5px;
  border-bottom: 1px solid black;
  color: black;
  padding: 5px;
}

.notification-page-single-container:hover {
  background: rgb(238,238,238);
  border-radius: 5px;
}

.notification-page-single-container>* {
  display: inline-block;
}

.notification-page-user-avatar {
  height: 50px;
  grid-row: 1;
  grid-column: 1;
}

.notification-page-message {
  grid-row: 1;
  grid-column: 2;
}

.notification-page-timestamp {
  grid-row: 2;
  grid-column: 2;
}