.image-crop-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5em;
    text-align: center;
}

.image-crop-file-input-container {
    grid-row: 1;
    grid-column: 1/ span 4;
    text-align: left;
}

.image-crop-file-input-container>* {
    display: inline-block !important;
}

.image-crop-toggle-preview {
    grid-row: 1;
    grid-column: 2;
}

.image-crop-clear {
    grid-row: 3;
    grid-column: 1;
}

.image-crop-reset-crop {
    grid-row: 3;
    grid-column: 2;
}

.image-crop-full-image {
    grid-row: 3;
    grid-column: 3;
}

.image-crop-use-image {
    grid-row: 3;
    grid-column: 2;
}

.image-crop-images {
    grid-row: 2;
    grid-column: 1/span 4;
    padding: 0.5em;
}

.image-crop-image-container  {
  margin: 5px;
  border-top: 1px solid rgba(100,100,100,0.7);
}

.image-crop-image-container * {
    max-height: 500px;
    margin: auto auto;
}

.image-crop-image-controls>* {
  display: inline-block;
  margin: 5px;
}

.image-crop-preview-title {
    grid-row: 1;
    grid-column: 2;
}

.image-crop-upload-status {
  grid-row: 3;
  grid-column: 1/span 4;
}

.image-crop-uploaded-images {
  grid-row: 4;
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns: 100%;
}

.image-crop-uploaded-image img {
  max-width: 100%;
}

.image-crop-how-to-use-image-list {
  margin: 0 auto;
  text-align: left;
}