.preview-vote-item {  
  height: 2em;
  width: 2em;
  margin: 0;
  padding: 0;
  border: none;
  grid-column: 1;
}

.preview-vote-item * {
  width: 100%;
  margin: 0;
  padding: 0;
  grid-column: 1;
}

.preview-vote-button {
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 50px;
}

.preview-vote-button:hover {
	background: #FFB137;
}

.preview-vote-button:focus, .preview-vote-button:active {
  background: transparent !important;
}

.preview-vote-button img {
  width: inherit;
}


#set-default-weight {
  margin-right: 5px;
}

.vote-slider-checkbox>* {
  margin-top: 10px;
  cursor: pointer;
}

.vote-failed-dialog {
  width: 400px;
  max-width: 90vw;
}