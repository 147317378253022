.feed-title {
  color: black;
}

.feed-sort-button-container {
  grid-row: 2;
  grid-column: 3;
  text-align: right;
}

.feed-type-button-container {
  grid-row: 2;
  grid-column: 3;
  text-align: left;
}

.feed-type-button-container-profile {
  grid-row: 3;
  grid-column: 1;
  background: transparent;
}

.feed-button {
  margin: 0 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.btn-selected {
  font-weight: bold;
  background-color: #ddd;
  border-width: 2px;
}

.feedmode-button {
  height: 50px;
  width: 50px;
  padding: 2px;
}

.feedmode-button img {
  height: 100%;
}

.feed-outer-container {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
}

.feed-grid-container, .feed-grid-container-blog {
  grid-row:2;
  grid-column: 3;
  padding: 10px;
  text-align: left;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 20px;
}

.feed-grid-container {
  margin: 0 auto;
  max-width: 600px;
}

.feed-grid-container-blog {
  max-width: 500px;
  margin: 0 auto;
}

.feed-grid-container>*, .feed-grid-container-blog>* {
  justify-self: center;
}

.grid-container-feed-object {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  width: 100%;
}

.preview-top-bar {
  grid-row: 1;
  grid-column: 1/span 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.preview-author {
  grid-row: 1 / span 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 45px 1fr;
}

.preview-author-img {
  grid-row: 1 / span 2;
  grid-column: 1;
}

.preview-author-img>img {  
  height: 40px;
  border-radius: 50%;
  margin-top: 2px;
}

.preview-author-text, .preview-author-text:hover {
  margin-left: 5px;
  color: black;
}

.preview-reblogged-by, .preview-reblogged-by:hover {
  grid-row: 1;
  grid-column: 2;
  margin: 5px 5px 0 0;
  font-style: italic;
  cursor: pointer;
  text-align: right;
  color:black;
}

.preview-reblogged-by img {
  max-height: 20px;
  border-radius: 50%;
}

.preview-created {
  grid-row: 2;
  grid-column: 2;
  font-style: italic;
  font-size: 0.9em;
}

.preview-delete-post {
  grid-row: 2;
  grid-column: 2;
}

.preview-delete-post-button {
  padding: 2px 5px;
  max-width: 60px;
  width: fit-content;
  float: right;
}

.preview-delete-post-button:hover {
  color: rgb(220,0,0);
}

.delete-post-popup-spinner {
  text-align: center;
}

.preview-title {
  grid-row: 2;
  grid-column: 1 / span 3;
}

.preview-title-text, .preview-title-text:hover {
  font-size: 1.4em;
  color: #000;
}

.preview-image-container {
	grid-row: 3;
	grid-column: 1/ span 3;
  height: 100%;
  max-height: 85vh;
}

.preview-video-container {
	grid-row: 3;
  grid-column: 1/ span 3;
}

.preview-video {
  width: 100%;
  height: 321px;
  max-width: 571px;
}

.preview-image-container-blog {
	grid-row: 3;
	grid-column: 1/ span 3;
  width: 100%;
  max-height: 40vh; 
  text-align: center;
}

.preview-video-container-blog {
	grid-row: 3;
  grid-column: 1/ span 3;
}

.preview-video-blog {
  width: 100%;
  height: 265px;
  max-width: 471px;
}


.preview-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: auto;
}

.preview-image-video {
  min-height: 30vh;
  max-height: 60vh;
  width: 100%;
  object-fit: cover;
  overflow: auto;
}

.preview-image-blog {
  width: 100%;
  object-fit: contain;
  max-height: 40vh;
}

.preview-bottom-bar {
  grid-row: 5;
  grid-column: 1/span 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  margin: 0 5px 5px 5px;
}

.preview-bottom-bar p {
  font-size: 120%;
}

.preview-votes {
  grid-row: 1;
  grid-column: 1/span 3;
  display: grid;
  grid-template-columns: 3em 1fr;
}

.preview-votes * {
  display: inline-block;
}

.preview-vote-amount {
  margin-top: 3px;
  grid-column: 2;
  text-align: left;
  cursor: pointer;
}

.preview-following-votes {
  grid-row: 2;
  grid-column: 1 / span 3;
}

.votes-popup {
  width: 400px;
  max-width: 95vw;
  margin: 10px auto;
}

.votes-popup-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.votes-popup-page-counter {
  grid-column: 1 / span 2;
  grid-row: 1;
  text-align: center;
  margin-bottom: 0.5em;
}

.votes-popup-previous, .votes-popup-next {
  max-height: 35px !important;
}

.votes-popup-previous {
  grid-column: 1;
  grid-row: 2;
}

.votes-popup-list {
  grid-column: 1 / span 2;
  grid-row: 3;
  margin: 0 auto;
  padding: 0;
  display: grid;
  width: 200px;
  max-width: 95vw;
}

.votes-popup-voter {
  list-style: none;
  max-height: 2em;
}

.votes-popup-voter.unvoted *, .votes-popup-voter.unvoted *:hover {
  color: grey !important;
  font-style: italic;
}

.votes-popup-voter-image {
  max-height: 2em;
  border-radius: 50%;
  margin-right: 0.5em;
}

.votes-popup-voter>a>* {
  display: inline-block;
}

.votes-popup-next {
  grid-column: 2;
  grid-row: 2;
}

.preview-comments {
  grid-row: 1;
  grid-column: 2;
  text-align: center;
}

.preview-comment-icon {
  display: inline;
  margin-right: 5px;
}

.preview-comments-text, .preview-comments-text:hover {
  color:black;
}

.preview-payout {
  grid-row: 1;
  grid-column: 3;
  text-align: right;
}

.preview-blog-body {
  word-wrap: break-word;
  grid-row: 4;
  grid-column: 1/span 3;
  padding: 5px;
}

.preview-body {
  max-height: 40vh;
  overflow: hidden;
}

.preview-goto-fullpost {
  margin: 0 auto;
  padding: 1vh 0;
  font-size: 1.5em;
  text-align: center;
}


/*.preview-wrapper {
	grid-column: 1/ span 4 ;
	grid-row: 1;
	padding: 10px;
}*/

.result-error-message {
  margin-top: 70px;
  color: black;
  border: 3px solid rgba(0,0,0,0.8);
  background-color: #ccc;
  padding: 5px;
}

.mobile-feed-popup {
  width: fit-content;
  max-width: 90%;
  margin: 20% auto;
}

.mobile-feed-popup .modal-title, .mobile-feed-popup button, .mobile-feed-popup a{
  font-size: 150%;
}

.mobile-feed-popup-body {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  width: fit-content;
  max-width: 60%;
  margin: 0 auto;
}

.mobile-feed-popup-body div, .mobile-feed-popup a {
  width: 100%;
}

.mobile-feed-popup-footer {
  text-align: center;
}
