.big-small-column{
  display: grid;
  grid-template-columns: 15% 85%;
  /*  background-color: lightgray;*/
  /*linear-gradient(to right,  rgb(228, 228, 228), rgb(228, 228, 228));*/
  background: rgb(228,228,228); 
}

.home-triple-column {  
  display: grid;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(228,228,228);
  grid-column-gap: 0;
  margin: 0;
}

.triple-column{
  display: grid;
  grid-template-columns: 15% 70% 15%;
  background-color: lightgray;
  grid-gap: 10px;
  min-height: 100vh;
}

.first-column{
  grid-column: 1;
}

.second-column{
  grid-column: 2;
}

.third-column{
  grid-column: 3;
}

.center-column{
  grid-column: 2;
  background-color: #ffffff;
  padding: 20px;
}

.padding-top{
  padding-top: 45px;
}

.home-center-column {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px 200px;
}

.more-margin-top {
  margin-top: 50px !important;
}

.home-fixed-container {
  position: fixed;
  display: grid;
  width: 100%;
  pointer-events: none;
  grid-template-columns: 200px 1fr 200px 200px;
  z-index: 2;
}

.home-fixed-container * {
  pointer-events: auto;
}

.feed-fixed-container-background {  
  background: rgb(228,228,228);
  z-index: 3;
  top: 55px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 55px;
}

.feed-fixed-container {
  background: rgb(228,228,228);
  overflow: hidden;
  position: fixed;
  z-index: 1;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px 200px;
  pointer-events: none;
}

.feed-fixed-container *, .feed-fixed-container-profile * {
  pointer-events: auto;
}

.feed-fixed-container>*, .feed-fixed-container-profile>*{
  display: inline-block;
}

.feed-fixed-container-profile {  
  background: transparent;
  padding-bottom: 5px;
  overflow: hidden;
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 200px;
  margin-top: 40px;
  pointer-events: none;
}

.feed-top-container {
  grid-row: 1;
  grid-column: 2;
  margin: 0 auto;
  text-align: center;
}

.feed-top-container-profile {
  grid-row: 1;
  grid-column: 3;
  
}

.feed-top-container>* {
  display: inline-block;
  margin-right: 5px;
}


.trending-tag-container {
  grid-row: 1;
  grid-column: 1;
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 2px;
  padding-left: 2px;
}

.trending-tag {
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.trending-tags-title {
  padding-left: 5px;
  text-align: center;
  color: #000000;
}

.trending-tag:hover {
  background-color: rgba(150,150,150,0.3);
}

.current-tag>* {
  background-color: rgba(100,100,100,0.3);
}

.top-trending-container {
  grid-row: 1;
  grid-column: 4;
}

.top-trending-post-container {
  display: grid;
  grid-template-columns: 20% 80%;
}

.top-trending-profile-picture {
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  max-height: 25px;
  border-radius: 50%;
}

.top-trending-author {
  grid-column: 2;
  grid-row: 1;
}

.top-trending-post-title {
  grid-column: 1 / span 2;
  grid-row: 2;
  word-break: break-word;
}

.top-trending-list{
  background-color: #f5f5f5;
  margin: 15px;
  border-radius: 5px;
  padding: 0;
  list-style: none;
  padding: 5px;
}

.top-trending-list-item {
  padding: 5px 10px 5px 0px;
  border-radius: 5px;
}

.top-trending-list-item:hover {
  background: rgb(228,228,228);
}

.home-right-column {
  grid-row: 1;
  grid-column: 3;
}

.discover-page {
  width: 90%;
  margin: 0 auto;
  background-color: white;
  min-height: 100vh;
}

.discover-page-expand-button {
  margin-top: 10px;
  margin-bottom: 50px;
}

.discover-container {
  min-height: 300px;
  font-size: 0.9em;
  height: fit-content;
  height: -webkit-fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  max-height: 70em;
  background: white;
  grid-row: 1;
  grid-column: 3;
  padding: 5px;
  border-radius: 5px;
  display: grid;
  margin-top: 10px;
  grid-row-gap: 5px;
}

.discover-container>* {
  height: -moz-min-content;
}

.discover-refresh-button {
  margin-left: 5px;
  margin-top: 5px;
}

.discover-full-list, .discover-full-list:hover {
  text-align: right;
  margin-right: 5px;
  font-size: 120%;
  color: inherit;
}

.discover-person-container {
  border-top: 1px solid rgb(228,228,228);
}

.discover-person-container:hover {
  background: rgb(228,228,228);
}


.discover-person-container>a {
  grid-row: 1;
  grid-column: 1;
}

.discover-person-container>*{
  display: inline-block;
}

.discover-profile-avatar {
  max-height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.discover-page .discover-profile-avatar {
  max-height: 50px;
}

.discover-person-followed-by {
  color: black;
}

.discover-person-show-connections {
  grid-row: 1;
  grid-column: 2;
  float: right;
}


.error-404-page {
  margin-left: 20px;
}

@media only screen and (max-width: 1000px) {

  .nav-collapse {
    text-align: center;
  }

  .discover-container {
    display: none !important;
  }

  .feed-outer-container {
    grid-column: 2 / span 2 !important;
  }
}