.post-body-editor {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  min-height: 250px;
  padding: 5px;
}

.post-body-editor>div {
  padding: 1px;
}

.post-body-editor>div>div {
}

.blogform {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.public-DraftEditor-content {
  min-height: 250px;
}

.blogform-center-column {
  height: fit-content;
  height: -moz-fit-content;
  grid-column: 2;
  background-color: #ffffff;
  padding: 20px;
}

.blogform-header>* {
  display: inline-block;
}

.blogform-header-avatar {
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}

.rdw-dropdown-optionwrapper {
  overflow: hidden !important;
}

.helpbox-button {
  border: none;
  margin: 0;
  max-width: 50px;
}

.blogform-trigger-uploaded-popup {
  float: right;
}

.uploaded-images-popup-container {
  max-width: 100%;
}

.uploaded-images-popup-image-container {
  cursor: pointer;
  width: 300px;
  text-align: center;
}

.uploaded-images-popup-image-container img {
  max-height: 100%;
  max-width: 100%;
}

.glyphicon.glyphicon-question-sign {
  font-size: 20px;
}

.submit-form-container {
  width: 100%;
  text-align: left;
}

.upload-image-preview {
  height: 500px;
}

.postblog-post-preview .readpost-bottom-bar, .postblog-post-preview .post-reply-button {
  display: none !important;
}

.postblog-upload-status {
  margin: 10px auto;
}

.hidden {
  display: none;
}