.home-banner {
  background: linear-gradient(to right,#ffff, #ffff);
  text-align: center;
  display: grid; /*oppretter grid for banner */
  grid-template-columns: 20% 60% 20%; /*definerer grid størrelsene */
}

.home {
  background: linear-gradient(to right, #ffff, #ffff);

}

.bannerTitle {
  grid-row: 1;
  grid-column: 2;
  background-color: white;
}

.bannerTags {
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  background-color: white;
}

.fa {
  background: transparent;
    color: white;
    width: 35px;
}

.socialMedia {
  grid-column: 3;
  grid-row: 2;
}

.mobile-new-post-button {
  background-color: transparent;
  border:none;
  margin: 0 auto;
  text-align: center;
  float: center;
}

.mobile-new-post-container {
  width: 100%;
  text-align: center;
}

.mobile-new-post-button {
  margin: 2px auto;
  height: 35px;
  width: 35px;
  padding: 0;
  font-size: 180%;
  border-radius: 50%;
  border: 2px solid black;
}

.mobile-new-post-button:focus {
  color: #8c8c8c;
}