.search-results-page {
    margin: 20px;
    display: block;
    text-align: center;
}

.search-results-container {
    margin: 0 auto;
    display: block;
    width: fit-content;
    width: -webkit-fit-content;
    width: -ms-fit-content;
    width: -moz-fit-content;
    max-width: 80%;
}

.search-result {
    padding: 5px;
    margin: 5px;
    font-size: 1.5em;
    display:block;
    background-color: white;
    text-align: left;
}

.search-result:hover {
    background: rgb(228,228,228);
}

.search-result * {
    display: inline;
}

.search-result-avatar {
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
}

.new-search-container {
    width: 80%;
    width: fit-content;
    max-width: 400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 4fr 1fr;
}