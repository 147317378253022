@media only screen and (max-width: 767px) {
  .padding-top {
    padding-top: 50px;
  }

  .top-voted-list-container {
    display: none !important;
  }

  .trending-tags-container {
    display: none !important;
  }

  .home-triple-column {
    display: block;
  }

  .home-center-column, .feed-fixed-container {
    display: block;
  }


  .feed-fixed-container {
    text-align: center;
    pointer-events: none;
  }

  .feed-fixed-container * {
    pointer-events: auto;
  }

  .feed-outer-container {
    display: grid !important;
  }

  .feed-grid-outer-container, .feed-grid-container, .feed-grid-container-blog{
    padding: 0 !important;
  }

  .feed-grid-container, .feed-grid-container-blog {
    max-width: 100% !important;
  }

  .triple-column {
    display: block;
  }

  .read-post {
    padding: 0 10px 10px 10px;
    grid-column: 1;
  }

  .comments {
    grid-column: 1;
    padding: 10px;
  }

  .nav-mobile-dropdown {
    display: block !important;
  }

  .nav-collapse>* {
    display: none;
  }
}