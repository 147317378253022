.HelpPage {
  grid-template-columns: auto auto;
  grid-gap: 5px;
  background-color: white;
  height: 90vh;
  padding-top: 10px;
  display: grid;
  grid-column: 2;
  padding: 50px;
  
}

.HelpInfo {
  grid-column: 1 ;
  grid-row: 1;
}
