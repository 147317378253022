.license-page {
    width: 80%;
    margin: 0 auto;
}

.license-container {

}

.license-name {
    font-weight: bold;
}

.license-text {
    line-height: 200%;
}