.settings-page {
    background: rgb(228,228,228);
    min-height: 100vh;
    text-align: center;
    margin: 0 auto;
}

.settings-page>* {
  margin: 10px auto;
}

.settings-feed-mode-buttons>* {
  margin: 2px;
}

.settings-vote-weight-input-bar {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-column-gap: 10px;
    max-width: 200px;
    margin: 0 auto;
}

.settings-vote-weight-input-bar>* {
  display: inline-block;
}

.settings-vote-weight-input {
    text-align: center;
    width: 100%;
}

.settings-weight-min, .settings-weight-max {
    width: fit-content;
    max-width: 60px;
}

#vote-weight-slider {
  margin: 0 auto;
  width: 90%;
  max-width: 600px;
  -webkit-appearance: none;
}

input[type=range]::-ms-fill-lower {
  background: linear-gradient(to right, #00c1cd, #0085cd);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, #00c1cd, #0085cd);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
  background: linear-gradient(to right, #00c1cd, #0085cd);
}
input[type=range]:focus::-ms-fill-upper {
  background: linear-gradient(to right, #00c1cd, #0085cd);
}

@mixin vote-slider-track() {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: linear-gradient(to right, #FFB137, #FF8D36);
  border-radius: 1.3px;
  border: 0.2px solid #010101;  
}

#vote-weight-slider {
  &::-webkit-slider-runnable-track { @include vote-slider-track }
  &::-moz-range-track { @include vote-slider-track }
  &::-ms-track { @include vote-slider-track }
}

@mixin vote-slider-thumb() {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}

#vote-weight-slider {
  &::-webkit-slider-thumb { @include vote-slider-thumb}
  &::-moz-range-thumb { @include vote-slider-thumb }
  &::-ms-thumb { @include vote-slider-thumb }
}


.settings-footer {
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  padding: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
}