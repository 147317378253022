.app-header {
  height: 100px;
  width: auto;
  padding-bottom: 50px;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  pointer-events: none;
}

.container-fluid * {
  pointer-events: auto;
}

.register-login-buttons {
  margin-top: 6px;
}

.navbar-dropdown>* {
  padding-top: 7px !important;
}

.navbar-dropdown>ul>li>.selected {
  background: rgb(228,228,228);
} 

.profile-picture-navbar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.navbar {
  min-height: 45px;
  max-height: 45px;
  background: linear-gradient(to right, #00c1cd, #0085cd);
  display:block;
}

.navbar-home {
  padding: 8px 5px 5px 5px;
}

.navbar-item {
  margin-top: 6px;
}

.navbar-text-button.open>a {
  margin: 0 5px;
}

.navbar-text-button.open>a>span {
  color: black !important;
}

.nav-collapse {
  max-height: 100% !important;
  overflow-x: hidden;
  border: none;
  pointer-events: none;
}

.nav-collapse * {
  pointer-events: auto;
}

.nav-collapse .btn, .nav-collapse .form-control {
  padding: 4px 7px !important;
}

.nav-collapse, .navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 820px) {
  .navbar {
    max-height: 100%;
  }
}

.navbar-small {
  display:block;
}

.navbar-small>ul {
  display: inline-block;
}

.navbar-home{
  color: rgb(228,228,228) !important;
  font-size: 2em;
  padding-left: 25px;
  padding-right: 10px;
}

.navbar-home.smaller-brand {
  padding: 10px 5px;
}

.navbar-logo {
  width: 40px;
  height: 40px;
  margin-top: -10px;
}

.navbar-search-input-container {
  text-align: center;
  display: grid;
  grid-column-gap: 0px;
  height: 100%;
  margin-right: 5px;
}

.navbar-search-input {
  width: 99% !important;
}

.navbar-search-input:focus {
  box-shadow: 0 0 6px rgba(17, 86, 127, 1);
}

.navbar-clear-search, .navbar-clear-search:active, .navbar-clear-search:focus {
  color: #666;
  padding: 0;
  border: 0;
  background: transparent;
  position: relative;
  bottom: 2em;
  left: 6em;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 50%;
  margin: 0;
  width: 1.3em;
}

.navbar-clear-search:hover {
background: rgb(228,228,228);
}

.navbar-search-button {
  grid-column: 2;
  height: fit-content;
  max-height: 30px;
}

.navbar-search-results-container {
  position: relative;
  background: white;
  border: 1px solid rgb(38,38,38);
  border-radius: 5px;
}

.navbar-search-results {
  list-style: none;
  text-align: left;
  padding: 0;
}

.query-selected {
  background-color: rgb(228,228,228);
}

.navbar-search-profile-link {
  padding: 10px;
  display: block !important;
}

.navbar-search-profile-link:first-child {
  border-radius: 5px 5px 0 0;
}

.navbar-search-profile-link:last-child {
  border-radius: 0 0 5px 5px;
}

.navbar-search-profile-link:hover {
  background-color: rgb(170,170,170);
}

.navbar.center .navbar-search-input-container .nav {
 display:block;
 margin-left: auto;
 margin-right: auto;
 width: 1000px;
 float: none;
}

.mobile-feed-controller {
  pointer-events: none;
  margin: 5px -1px;
}


.nav-mobile-dropdown {
  display: none;
  font-size: 140%;
  color: white;
  max-height: 100% !important;
}

.nav-mobile-dropdown a {
  padding: 8px !important;
  color: white !important;
}

.nav-mobile-dropdown>ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.nav-mobile-dropdown>ul::before {
  content: none;
}

.nav-mobile-dropdown li {
  background-color: rgba(228,228,228, 0.2);
  margin: 2px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.2);
}

.navbar-text-button>* {
  font-size: 1.2em;
  padding-top: 10px !important;
  color: rgb(228,228,228) !important;
}

.navbar-text-button>a:hover {
  color: rgb(200,200,200) !important;
}

.navbar-text-button>.selected, .navbar-text-button>.selected:hover {
  color: #0085cd !important;
  background: rgb(228,228,228) !important;
}

.notifications-button.selected {
  background: rgb(228,228,228) !important;
}

a.link{
  text-decoration: none;
  color: #fff;
}

a.hover{
  text-decoration: underline;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.navbar-notification-icon {
  height: 35px;
  width: 35px;
  margin-top: -10px;
}


.enable-cookies-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 20;
  text-align: center;
}

.enable-cookies-banner {
  background: orange;
  width: fit-content;
  width: -moz-fit-content;
  margin: 5px auto;
  border-radius: 5px;
  padding: 10px 40px;
  display: grid;
  grid-template-columns: 7fr 1fr;
  grid-column-gap: 10px;
}

.feed-controller-container> ul {
  text-align: center;
  right: -12em;
  left: -12em;
  list-style: none;
}

.feed-controller-container-profile>ul {
  text-align: center;
  right: -5em;
  left: -5em;
  list-style: none;
}

.feed-controller-container>ul *, .feed-controller-container-profile>ul * {
  display: inline-block;
}

.feed-select-tag-popup {
  width: 110%;
  margin: 2em auto;
  text-align: center;
}

.feed-select-tag-popup-body {
  display:grid;
  grid-template-columns: 1fr;
  text-align: center;
  width: fit-content;
  max-width: 60%;
  margin: 0 auto;
}

.mobile-popup-body>a>img {
  height: 50px;
  display: inline-block;
}

.mobile-popup-app-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.mobile-popup-googleplay {
  grid-row: 2;
  grid-column: 1;
}

.mobile-popup-googleplay img {
  max-height: 5.5em;
}

.mobile-popup-appstore {
  grid-row: 2;
  grid-column: 2;
}

.mobile-popup-appstore img {
  max-height: 5em;
}

@media (max-width: 1050px) {
  .my-feed-button {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .my-profile-button {
    display: none !important;
  }
}

@media (max-width: 860px) {
  .notifications-button {
    display: none!important;
  }
}