.edit-profile-page {
    margin: 55px 0 0 10px;
}

.form-control {
    width: 90%;
}

.profile-image-preview {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.cover-image-preview {
    height: 100px;
    width: 300px;
}

.edit-profile-image-crop {
    width: 80%;
}